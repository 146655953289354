<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 22C6.2 22 5.72933 21.8043 5.338 21.413C4.946 21.021 4.75 20.55 4.75 20V4C4.75 3.45 4.946 2.979 5.338 2.587C5.72933 2.19567 6.2 2 6.75 2H18.75C19.3 2 19.771 2.19567 20.163 2.587C20.5543 2.979 20.75 3.45 20.75 4V20C20.75 20.55 20.5543 21.021 20.163 21.413C19.771 21.8043 19.3 22 18.75 22H6.75ZM12.5 10.55L14.25 9.5L16 10.55C16.1667 10.65 16.3333 10.65 16.5 10.55C16.6667 10.45 16.75 10.3083 16.75 10.125V4H11.75V10.125C11.75 10.3083 11.8333 10.45 12 10.55C12.1667 10.65 12.3333 10.65 12.5 10.55Z"
      fill="#365FA3"
    />
  </svg>
</template>
